import type { ReactElement } from 'react';
import type { CategoriesViewProps, CategoryRequestResultProps } from '../types';
import type { GetStaticProps, GetStaticPropsContext } from 'next';

import * as React from 'react';

import { View } from '../generated/index';
// import { adsRequest } from '../utils/ads-request';

// import { CollectionsA as View } from '../containers/CollectionsA';
// import { CollectionsB as View } from '../containers/CollectionsB';
// import { CollectionsC as View } from '../containers/CollectionsC';
// import { CollectionsD as View } from '../containers/CollectionsD';
// import { CollectionsE as View } from '../containers/CollectionsE';
import { BaseLayout } from '../layouts/BaseLayout';

import { CollectionTypesEnum } from '../types/enum';
import { CollectionPageJsonLd, CarouselJsonLd, NextSeo, BreadcrumbJsonLd } from 'next-seo';
import { translateUrl } from 'next-translate-routes';
import { useRouter } from 'next/router';
import { getAllCollections, getCollection } from '../app/api';
import { useTranslation } from 'next-i18next';
import { mappingCollection } from '../app/mappings';
import { useAppContext } from '../contexts/AppContext';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { cleanSlug, getLocalizedPaths, getLocalizedSlugs } from '../utils/localize-helpers';
import { getNonPageNamespaces } from '../utils/get-non-page-namespaces';

import { AdvertisementSlot } from '../components/AdvertisementSlot';
import styled from '@emotion/styled';
import { SubscriptionModal } from '../components/SubscriptionModal';

const AdvertisementSlotBottom = styled(AdvertisementSlot)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  marginTop: theme.spacing(4),
  position: 'sticky',
  bottom: '0',
  margin: 'auto',
  background: 'white',
  width: '728px',
  height: '90px',
  zIndex: '1',
  [theme.breakpoints.down('lg')]: {
    width: '364px',
    height: '45px',
  },
}));

const AdvertisementSlotBottomSticky = styled('div')(({ theme }) => ({
  boxShadow: theme.shadows[0],
  marginTop: theme.spacing(4),
  position: 'sticky',
  bottom: '0',
  margin: 'auto',
  background: 'white',
  width: '728px',
  height: '90px',
  zIndex: '1',
  [theme.breakpoints.down('lg')]: {
    width: '364px',
    height: '45px',
  },
}));

export default function IndexPage(props: CategoriesViewProps) {
  const { collections, pageContext } = props;
  const { t, i18n } = useTranslation(['common', 'index']);
  const appContext = useAppContext();
  const { name, assetAbsoluteUrl, url, componentOptions } = appContext.useConfig();
  const router = useRouter();
  const paths = getLocalizedPaths(router);
  const slugs = getLocalizedSlugs(router);
  const pageContextWithPaths = { ...pageContext, paths, slugs, slug: cleanSlug(router.pathname) };
  const siteUrl = `${url?.replace(/\/+$/, '')}`;
  const stickyAdvertisement = componentOptions?.component_advertisement_show;

  let seoTitle = i18n.exists('index:title', { fallbackLng: pageContextWithPaths.locale })
    ? t('index:title')
    : t('common:title');
  let seoDescription = i18n.exists('index:description', { fallbackLng: pageContextWithPaths.locale })
    ? t('index:description')
    : t('common:description');

  // Customized SEO
  const { seo } = appContext.useSeo();

  // Homepage page
  if (seo?.pages) {
    const currentPageSEO = seo.pages?.home;
    if (currentPageSEO && router.locale) {
      const seoCurrentLanguage = currentPageSEO?.[router.locale];
      if (seoCurrentLanguage?.title) {
        seoTitle = seoCurrentLanguage?.title;
      }
      if (seoCurrentLanguage?.description) {
        seoDescription = seoCurrentLanguage?.description;
      }
    }
  }

  const banners = ['idhb-bottom-90'];

  // React.useEffect(() => {
  //   adsRequest(banners);
  // }, []);

  // React.useEffect(() => {
  //   const { events } = router;
  //   const handleRouteChange = () => {
  //     adsRequest(banners);
  //   };

  //   events.on('routeChangeComplete', handleRouteChange);
  //   events.on('hashChangeComplete', handleRouteChange);
  //   return () => {
  //     events.off('routeChangeComplete', handleRouteChange);
  //     events.off('hashChangeComplete', handleRouteChange);
  //   };
  // }, [router]);

  const showSubscriptionModal = componentOptions.component_project_subscription_mode;

  return (
    <>
      {showSubscriptionModal && <SubscriptionModal />}
      <NextSeo title={seoTitle} description={seoDescription} />
      {collections.length > 0 && collections[0]?.games.length > 0 && (
        <>
          <CarouselJsonLd
            ofType="default"
            data={collections[0].games.map((game) => ({
              url: `${siteUrl}${translateUrl(`/game/${game.slug}`, router.locale || 'en')}`,
            }))}
          />
          <BreadcrumbJsonLd
            itemListElements={pageContextWithPaths.breadcrumbs.map((crumb, index) => ({
              position: index + 1,
              name: crumb.name,
              item: crumb.href,
            }))}
          />
          <CollectionPageJsonLd
            name={t('common:title')}
            hasPart={collections.map((collection) => ({
              about: collection.description,
              author: { name },
              name: collection.name,
              audience: 'Internet',
              keywords: 'schema',
              thumbnailUrl: `${assetAbsoluteUrl}${
                collection.images.cover1x1 || collection.images.cover
              }/?ar[r]=1x1&ar[w]=512&fmt=.png`,
              image: `${assetAbsoluteUrl}${collection.images.cover1x1 || collection.images.cover}/`,
            }))}
          />
        </>
      )}
      <View {...props} pageContext={pageContextWithPaths} />
      {/* {stickyAdvertisement && (
        <AdvertisementSlotBottomSticky id='dedegamescom47329' style={{width: '728px', height: '90px'}} /> 
        <AdvertisementSlotBottom elevation={1} slotIdentifier="idhb-bottom-90" variant="728x90" />
      )} */}
    </>
  );
}

// Adding getLayout property. Allowing us to return a React component for the layout.
// This allows us to define the layout on a per-page basis. Since we're returning a function,
// we can have complex nested layouts if desired.
IndexPage.getLayout = function getLayout(page: ReactElement) {
  const { props } = page;
  const { pageContext } = props;
  return (
    <BaseLayout pageType="index" pageContext={pageContext}>
      {page}
    </BaseLayout>
  );
};

export const getStaticProps: GetStaticProps = async (context: GetStaticPropsContext) => {
  const url = `${process.env.PROJECT_URL?.replace(/\/+$/, '')}`;
  const locale: string = (context.locale || context.defaultLocale) as string;
  const customIndexCollections = process.env.PROJECT_PAGE_INDEX_COLLECTIONS;
  let collections: CategoryRequestResultProps[];
  // Custom collections added on YAML Config
  const isCustomCollections = Array.isArray(customIndexCollections) && customIndexCollections.length > 0;
  if (isCustomCollections) {
    const promises: Promise<CategoryRequestResultProps | undefined>[] = customIndexCollections.map((collection) =>
      getCollection(
        collection.slug,
        collection?.type || CollectionTypesEnum.COLLECTION,
        {
          _fieldsGame: 'image', // Include "image" property for each game.
          _gameLimit: collection?.query?.limit || 12,
        },
        {
          ssr: true,
          locale,
        }
      )
    );
    collections = (await Promise.all(promises)) as CategoryRequestResultProps[];
  } else {
    collections = (await getAllCollections(
      CollectionTypesEnum.COLLECTION,
      {
        _fieldsGame: 'image', // Include "image" property for each game.
        _gameLimit: 12,
        _limit: 12,
      },
      {
        ssr: true,
        locale,
      }
    )) as CategoryRequestResultProps[];
  }

  const categories: CategoryRequestResultProps[] | undefined = await getAllCollections(
    CollectionTypesEnum.CATEGORY,
    {
      _fieldsGame: 'image', // Include "image" property for each game.
      _gameLimit: 12,
      _limit: 8,
    },
    {
      ssr: true,
      locale,
    }
  );

  if (!collections) return { notFound: true };

  const page = {
    title: null,
    body: null,
    locale: context.locale,
  };
  const { title, body } = page;
  const pageContext = {
    locale: page.locale,
    locales: context.locales,
    defaultLocale: context.defaultLocale,
    slugs: [],
    slug: '',
    breadcrumbs: [{ href: url, path: '/', name: 'Home' }],
  };

  return {
    props: {
      title,
      body,
      collections:
        Array.isArray(collections) &&
        collections.map((collection: CategoryRequestResultProps) => ({
          ...mappingCollection(collection),
        })),
      categories:
        Array.isArray(categories) &&
        categories.map((category: CategoryRequestResultProps) => ({
          ...mappingCollection(category),
        })),
      global: {
        popular: [],
        latest: [],
      },
      pageContext,
      // Get our translations by namespace and locale.
      ...(await serverSideTranslations(locale as string, ['common', 'index', ...getNonPageNamespaces(locale)])),
    },
    // Incremental Static Regeneration (ISR).
    // https://nextjs.org/docs/basic-features/data-fetching/incremental-static-regeneration
    revalidate: parseInt(process.env.PROJECT_SSR_REVALIDATE_SECONDS || '') || 60,
  };
};
