import type { Theme } from '@emotion/react';
import type { PaperColorElevationProps } from '../PaperColorElevation';

import * as React from 'react';
import styled from '@emotion/styled';
import { Typography as MuiTypography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useEditorHandlers } from './@editor';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { PaperColorElevation } from '../PaperColorElevation';
import { useAppContext } from '../../contexts/AppContext';
import useLocalStorage from '../../utils/use-local-storage';

export type PaperColorElevationPropsOmit = Omit<PaperColorElevationProps, 'variant'>;
export interface AdvertisementSlotProps extends PaperColorElevationPropsOmit {
  className?: string;
  slotIdentifier: string;
  variant: '300x600' | '160x600' | '120x600' | '320x50' | '728x90' | '970x90' | '970x250' | '336x280' | '300x250';
  alignment?: 'left' | 'right' | 'center';
  areaName?: string;
  configuration?: any;
}

interface AdvertisementSlotStyleProps extends AdvertisementSlotProps {
  theme: Theme;
}

const Root = styled(PaperColorElevation)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(0),
}));

const Label = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: theme.spacing(2),
  padding: theme.spacing(0.25, 1),
  color: theme.theme_component_advertisement_slot_label_color || theme.palette.secondary.contrastText,
  backgroundColor: theme.theme_component_advertisement_slot_label_background_color || theme.palette.secondary.main,
  borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
  transform: 'translateY(-90%) scale(0.75)',
}));

const Inner = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const SlotContainer = styled.div<AdvertisementSlotStyleProps>(({ variant }) => {
  let styles;
  if (variant) {
    switch (variant) {
      case '120x600':
        styles = {
          width: 120,
          height: 600,
        };
        break;
      case '320x50':
        styles = {
          width: 320,
          height: 50,
        };
        break;
      case '970x90':
        styles = {
          width: 970,
          height: 90,
        };
        break;
      case '970x250':
        styles = {
          width: 970,
          height: 250,
        };
        break;
      case '336x280':
        styles = {
          width: 336,
          height: 250,
        };
        break;
      case '300x250':
        styles = {
          width: 300,
          height: 250,
        };
        break;
      case '728x90':
        styles = {
          width: 728,
          height: 90,
        };
        break;
      case '300x600':
        styles = {
          width: 300,
          height: 600,
        };
        break;
      case '160x600':
        styles = {
          width: 160,
          height: 600,
        };
        break;
    }
  }
  return {
    ...styles,
    overflow: 'hidden',
  };
});

export const AdvertisementSlot = (props: AdvertisementSlotProps) => {
  const { className, variant, slotIdentifier, alignment, configuration, ...other } = props;

  const captionRef = React.useRef<HTMLInputElement | null>(null);
  const themeOverride = useTheme();
  const { t } = useTranslation('advertisement_slot');
  
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();
  const subMode = componentOptions.component_project_subscription_mode;
  const [userToken, setUserToken] = useLocalStorage<string | undefined>('user-token', '');

  const hideAdvertisement = subMode && userToken;

  return (
    <Root className={className} {...useEditorHandlers('Advertisement Slot', configuration)} {...other}>
      {hideAdvertisement ? (
        <></>
      ) : (
        <>
          <Label>
            <Typography ref={captionRef} variant="caption" component="div">
              {t('label', 'Advertisement')}
            </Typography>
          </Label>
          <SlotContainer variant={variant} slotIdentifier={slotIdentifier} theme={themeOverride}>
            <Inner
              id={slotIdentifier}
              style={{
                justifyContent: alignment,
              }}
            />
          </SlotContainer>
        </>
      )}
    </Root>
  );
};
