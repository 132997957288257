import type { CategoriesViewProps, CategoryProps, GameProps } from '../../types';

import * as React from 'react';
import dynamic from 'next/dynamic';
import styled from '@emotion/styled';
import { useInView } from 'react-intersection-observer';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
// import { adsRequest } from '../../utils/ads-request';
import { useAppContext } from '../../contexts/AppContext';
import { Link } from '../../components/Link';
import { CollectionSection } from '../../components/CollectionSection';
import { Title } from '../../components/Title';
import { BreadCrumbs } from '../../components/BreadCrumbs';
import Container from '@mui/material/Container';
import { CardTypeEnum } from '../../types/enum';
import useLocalStorage from '../../utils/use-local-storage';
import { useTranslation } from 'react-i18next';
import { useCursorContext } from '../../contexts/CursorContext';
import useFlattenedGameData from '../../utils/useFlattenArray';

const AdvertisementSlot = dynamic<any>(
  () => import('../../components/AdvertisementSlot').then((mod) => mod.AdvertisementSlot),
  {
    ssr: false,
  }
);
const CarouselList = dynamic<any>(() => import('../../components/CarouselList').then((mod) => mod.CarouselList), {
  ssr: false,
  // suspense: true,
});

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(2),
}));

const AdCollectionContainer = styled(AdvertisementSlot)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',

  background: 'none',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4, 2),
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(2, 0),
  },
}));

const Ads = ({ style, slotIdentifier, variant }: any) => {
  return (
    <AdCollectionContainer
      style={style}
    >
      <AdvertisementSlot slotIdentifier={slotIdentifier} variant={variant} />
    </AdCollectionContainer>
  )
}

export const CollectionsA = (props: CategoriesViewProps) => {
  const { collections, pageContext } = props;
  const { t } = useTranslation('collections_a');
  const theme = useTheme();
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();
  const { ref, inView } = useInView();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const isBetweenMdAndLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { panel } = useCursorContext();

  const collectionsBeforeAdContainer =
    collections?.filter((c) => !c.code.includes('recommended')).slice(0, Math.ceil(collections.length / 2)) || [];
  const collectionsAfterAdContainer =
    collections?.length > 1
      ? collections?.filter((c) => !c.code.includes('recommended')).slice(Math.ceil(collections.length / 2)) || []
      : [];

  // React.useEffect(() => {
  //   adsRequest(['idhb-middle-970']);
  // }, []);

  const cardType = componentOptions?.component_game_carousel_card_type;

  const [lastPlayedGames] = useLocalStorage<GameProps[] | undefined>('lastplayed:current', []);
  const showLastPlayedCarousel = componentOptions?.component_homepage_show_last_played_carousel;

  const recommendedCollection = collections.find((c) => c.code.includes('recommended')) || { games: [] };

  const flattenedData = useFlattenedGameData(lastPlayedGames || []);

  return (
    <>
      <Container maxWidth={false}>
        <BreadCrumbs breadcrumbs={pageContext.breadcrumbs} />
      </Container>
      <Root ref={ref}>
        {showLastPlayedCarousel && flattenedData && flattenedData.length > 0 && (
          <CollectionSection key={'last-played-section'}>
            <Title component="h2" variant="h2">
              <Link href={'/'} title={t('my_last_played', 'My Last Played')} underline="hover">
                {t('my_last_played', 'My Last Played')}
              </Link>
            </Title>
            <CarouselList
              padding={theme.spacing(2)}
              items={[
                ...flattenedData,
                ...(recommendedCollection.games || []).map((game) => ({
                  ...game,
                  trend: true,
                })),
              ]}
              cardOptions={{
                width: componentOptions.component_card_width || 512,
                height: componentOptions.component_card_height || 288,
                sizes: '(min-width:1200px) 16.666vw, (min-width:900px) 25vw, (min-width:600px) 33.333vw, 50vw',
                layout: 'responsive',
                template: cardType || CardTypeEnum.EXTENSIVE,
                typographyOptions: {
                  align: 'left',
                },
              }}
            />
          </CollectionSection>
        )}
        {collectionsBeforeAdContainer.map(
          (collection: CategoryProps, index: number) =>
            collection.games.length > 0 &&
            (inView || index === 0) && (
              <CollectionSection key={`${collection.id}${index}`}>
                <Title component={index === 1 ? 'h1' : 'h2'} variant="h2">
                  <Link
                    href={`/${collection.type}/${collection.slug}`}
                    title={collection.name || undefined}
                    underline="hover"
                  >
                    {collection.name}
                  </Link>
                </Title>
                <CarouselList
                  padding={theme.spacing(2)}
                  items={collection.games}
                  cardOptions={{
                    width: componentOptions.component_card_width || 512,
                    height: componentOptions.component_card_height || 288,
                    sizes: '(min-width:1200px) 16.666vw, (min-width:900px) 25vw, (min-width:600px) 33.333vw, 50vw',
                    layout: 'responsive',
                    template: cardType || CardTypeEnum.EXTENSIVE,
                    typographyOptions: {
                      align: 'left',
                    },
                  }}
                />
              </CollectionSection>
            )
        )}
        {!componentOptions.component_ads_disabled && (
          <>
            {(lg && !componentOptions.component_middle_a_ads_disable) && (
              <AdCollectionContainer slotIdentifier="dedegamescom47330" variant={lg ? '970x250' : md ? '728x90' : '320x50'} />
            )}
            {(isBetweenMdAndLg && !componentOptions.component_middle_a_ads_tablet_disable) && (
              <AdCollectionContainer slotIdentifier="dedegamescom47329" variant={lg ? '970x250' : md ? '728x90' : '320x50'} />
            )}
            {(mdDown && !componentOptions.component_middle_a_ads_mobile_disable) && (
              <AdCollectionContainer slotIdentifier="dedegamescom47332" variant={lg ? '970x250' : md ? '728x90' : '320x50'} />
            )}
          </>
        )}
        {inView &&
          collectionsAfterAdContainer.map(
            (collection: CategoryProps, index: number) =>
              collection.games.length > 0 && (
                <CollectionSection key={`${collection.id}${index}`}>
                  <Title component="h2" variant="h2">
                    <Link
                      href={`/${collection.type}/${collection.slug}`}
                      title={collection.name || undefined}
                      underline="hover"
                    >
                      {collection.name}
                    </Link>
                  </Title>
                  <CarouselList
                    padding={theme.spacing(2)}
                    items={collection.games}
                    cardOptions={{
                      width: componentOptions.component_card_width || 512,
                      height: componentOptions.component_card_height || 288,
                      sizes: '(min-width:1200px) 16.666vw, (min-width:900px) 25vw, (min-width:600px) 33.333vw, 50vw',
                      layout: 'responsive',
                      typographyOptions: {
                        align: 'left',
                      },
                    }}
                  />
                </CollectionSection>
              )
          )}
      </Root>
    </>
  );
};
